import * as React from 'react';
import { Component } from 'react';
import ArticleLayout from "../../articles/article-layout";
import marlene_img_1 from '../../../images/Marlene-McNeese-hero.jpg';
import marlene_img_1_mobile from '../../../images/Marlene-McNeese-hero-mobile.jpg';

const fullName = "Marlene McNeese";

const copyList = [

    {
        type: "image",
        content: {
            src: marlene_img_1,
            mobileSrc: marlene_img_1_mobile,
            alt: "Text reads The Leader over a woman looking confidently into camera",
            caption: "Text reads The Leader over a woman looking confidently into camera"
        }
    },
    {
        type: "text",
        content: "<span class=\"h3 text-transform-none\">A conversation with Marlene McNeese, Deputy Assistant Director at the Houston Health Department</span>"
    },
    // {
    //     type: "subheading2",
    //     content: "<p class=\"sub-title2\">The Leader"
    // },

    {
        type: 'text',
        content: "As early as Marlene McNeese can remember, she’s been crystal clear about her future. “I instinctively knew what my life’s work was going to be,” says the dogged HIV activist and 20-year veteran of the Houston Health Department, where she serves as Deputy Assistant Director, Bureau of HIV/STI and Viral Hepatitis Prevention. “I’ve always been a champion for the underdog, the protector.”"
    },
    {
        type: "blockquote",
        content: "“I’ve always been a champion for the underdog, the protector.”",
        author: fullName
    },
    {
        type: 'text',
        content: "She first flexed her advocacy muscle in high school, where she remembers sticking up for a friend. “One day, kids were going off about her hair and how she didn’t have anybody taking care of her. Then I went off. I said, ‘She’s more than her hair and her clothes. I know her spirit, and she’s better than all of you!’” McNeese later learned how impactful she’d been. “My friend said, ‘That was the first time I felt like anybody understood who I was on the inside.’”"
    },
    {
        type: "text",
        content: "By 1989, she was 21, out of college, and interviewing in Houston to be an outreach coordinator at a nonprofit serving local homeless and LGBTQ youth. Through that role, she’d learn how HIV might have touched not only those around her but also her own family. “My grandmother died abruptly in her fifties after receiving a blood transfusion,” she recalls. “Doctors didn’t tell us exactly what it was, they just talked about her immune system being compromised.” After connecting the dots between her new job and that tragedy, “I had no doubt that HIV would be my life’s work. Had we had the information, the openness to really discuss what we were experiencing, we could have wrapped around my grandmother in a deeper way during her final moments.”"
    },
    {
        type: "text",
        content: `Armed with a passion to better equip those impacted by HIV, McNeese sought out mentors and expanded 
        her knowledge of the epidemic and its growing impact on 
        Black women. “Today we are still looking back on how much we’ve lost and the missed opportunity 
        early on to avert HIV among Black women.” That said, for decades, she’s worked diligently to 
        effectively employ Houston resources to change the landscape, arming countless Houstonians 
        of color with accessible information, programming, and support systems that have saved lives. One example: HIP 
        HOP for HIV, “a public-private collaboration to host testing events each summer, bringing HIV 
        testing and other services to over 55,000 young adults over 10 years.” Additionally, during her tenure as a 
        board chair for the National Alliance of State and Territorial AIDS Directors,* McNeese shared her learnings 
        on racial equity with programmatic leaders from across the nation, advocating for the hardest-hit communities 
        to have a seat at the table. “It’s my responsibility to use what I know to have some impact in a 
        system that wasn’t built for us and strive to change that system from the inside.”`
    },
    {
      type: 'disclaimer'
    },
    // {
    //     type: "signatureName",
    //     content: "<h2 class=\"signatureName\">MARLENE McNEESE"
    // },
    // {
    //     type: "signaturedesc",
    //     content: "<h2 class=\"signaturedesc\">Houston Department of Health"
    // },
    {
        type: "text",
          content: "<a class=\"button large secondary\" href=\"/championsofchange\">Learn more about all our Champions of Change leaders"

    },

]


class Content extends Component {
  render() {
    return (
      <div className={`championsofchangearticle ${this.props.show ? 'd-block' : 'd-none'}`}>

      <ArticleLayout
        page={this.props.page}
        ariaLabel="Group of smiling Black women in baroque clothing sitting together in front of a purple velvet curtain"
        copyList={copyList}
        subheading="Champions of Change: A Celebration of Black Women Changemakers in HIV"
        heading="Marlene McNeese, The Leader"
      />

        {/*<Container fluid className={`${this.props.page} hero`} />
        <Container className={`${this.props.page}`}>
          <Row>
            <Col xs={9} lg={10} className='m-auto px-0'>
              <div className='hero-callout muted-yellow-dark'>
                <h1 className='sub-title '>Dafina</h1>
                <h1 className='title '>Dafina Ward - Champion of Change</h1>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </Col>
          </Row>
    </Container>*/}
      


      </div>
    );
  }
}

export default Content;
